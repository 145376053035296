var Isotope = require("isotope-layout");

let isoG = document.getElementsByClassName('isoGrid');
    if (isoG.length > 0) {
        var iso = new Isotope(".isoGrid", {
            itemSelector: ".isoGridItem",
            layoutMode: "masonry",
          });
    }



