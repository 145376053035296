import Swiper from "swiper";
// import Swiper, { Navigation, Pagination, Autoplay, Parallax, EffectFade } from 'swiper';
import { Navigation, Pagination } from "swiper/modules";
// import 'swiper/css';
// import 'swiper/css/effect-fade';
import "swiper/css/navigation";
import "swiper/css/pagination";
// import 'swiper/css/parallax';

let galleries = document.getElementsByClassName("gallery");

if (galleries.length > 0) {
  let i;
  for (i = 0; i < galleries.length; i++) {
    let theid = galleries[i].getAttribute("data-id");
    let mainElement = "#gallery-" + theid;
    let next = ".swiper-button-next-" + theid;
    let prev = ".swiper-button-prev-" + theid;
    let pag = ".swiper-pagination-" + theid;
    // let thedelay = 3000;
    let swiper = new Swiper(mainElement, {
      modules: [Navigation, Pagination],
      loop: true,
      init: false,
      // autoplay: {
      //     delay: thedelay,
      // },
      preloadImages: false,
      speed: 500,
      // parallax: true,
      slidesPerView: 1,
      watchSlidesVisibility: true,
      // loopedSlides: swiperlength,
      centeredSlides: true,
      spaceBetween: 0,
      // If we need pagination
      pagination: {
        el: pag,
        clickable: true,
      },
      navigation: {
        nextEl: next,
        prevEl: prev,
      },
    });
    swiper.init();
  }
}
